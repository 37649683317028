import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import {
  LogoFacebook24,
  LogoInstagram24,
  LogoYoutube24,
} from "@carbon/icons-react"

const SocialMediaBannerBlock = ({ block }) => {
  let hasImage = block.image?.filename?.length > 0 ? true : false
  let hasText = block.text?.length > 0 ? true : false
  return (
    <BlockWrapper block={block} blockWidth="screen" blockPadding="py-0">
      <div className="relative w-full h-[27.5rem] bg-primary">
        {hasImage && (
          <Image className="object-cover w-auto h-full" image={block.image} />
        )}
        <div
          className={`absolute inset-0 ${
            hasImage ? "bg-black bg-opacity-20" : ""
          } `}
        >
          <div className="h-full max-w-screen-xl mx-auto px-grid">
            <div className="flex flex-row flex-wrap items-center justify-center h-full text-white">
              <div className={`w-full max-w-sm ${hasText ? "mt-14" : ""}`}>
                <div className="flex flex-row justify-between ">
                  {block.link_facebook?.cached_url?.length > 0 && (
                    <Link
                      link={block.link_facebook}
                      className="transition-colors duration-500 hover:text-primary"
                    >
                      <LogoFacebook24 className="w-14 h-14" />
                    </Link>
                  )}
                  {block.link_instagram?.cached_url?.length > 0 && (
                    <Link
                      link={block.link_instagram}
                      className="transition-colors duration-500 hover:text-primary"
                    >
                      <LogoInstagram24 className="w-14 h-14" />
                    </Link>
                  )}
                  {block.link_youtube?.cached_url?.length > 0 && (
                    <Link
                      link={block.link_youtube}
                      className="transition-colors duration-500 hover:text-primary"
                    >
                      <LogoYoutube24 className="w-14 h-14" />
                    </Link>
                  )}
                </div>
                {hasText && (
                  <div className="w-full mt-6 text-xl text-center uppercase">
                    {block.text}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default SocialMediaBannerBlock
